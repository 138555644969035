/* Imports */

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

:root {
  
--font-general: 'Nunito';
--font-headings: 'Nunito';
--font-headings-weight: 500;
--font-size: 13px;

/* Links */

--link-ico-color: #f8951d;
--link-ico-color-hover: #d98918;

/* Color primary */

--primary-color-lighter: #fcefdf;
--primary-color-light: #fcab49;
--primary-color: #f8951d;
--primary-color-hue: 33;
--primary-color-saturation: 94.0;
--primary-color-rgb: 248,149,29;
--primary-color-dark: #dc7d0a;
--primary-color-darker: #c3710e;
--primary-color-contrast-text: #fff;
--primary-color-hover: #d98918;
--primary-color-hover-rgb: 217,137,24;
--primary-color-hover-contrast-text: #fff;

/* Color secondary */

--secondary-color-lighter: #fff4df;
--secondary-color-light: #ffdf9f;
--secondary-color: #ffc653;
--secondary-color-hue: 40;
--secondary-color-saturation: 100%;
--secondary-color-rgb: 255,198,83;
--secondary-color-dark: #d7a133;
--secondary-color-darker: #b5831e;
--secondary-color-contrast-text: #fff;
--secondary-color-hover: #eab240;
--secondary-color-hover-rgb: 234,178,64;
--secondary-color-hover-contrast-text: #fff;

/* Color tertiary */

--tertiary-color-lighter: #ffe3dd;
--tertiary-color-light: #f9937d;
--tertiary-color: #ff5733;
--tertiary-color-hue: 40;
--tertiary-color-saturation: 100.0%;
--tertiary-color-rgb: 255,87,51;
--tertiary-color-dark: #d13817;
--tertiary-color-darker: #961b00;
--tertiary-color-contrast-text: #fff;
--tertiary-color-hover: #f04c28;
--tertiary-color-hover-rgb: 240,76,40;
--tertiary-color-hover-contrast-text: #fff;

/* Login */

--login-color: #fff;
--login-img: url('assets/img/login-img.jpg');
--login-img-repeat: no-repeat;
--login-img-position: inherit;
--login-img-attachment: fixed;
--login-img-size: cover;
--login-welcome-display: block;
--login-welcome-color: #f8951d;
--login-container-color: rgba(255,255,255,.9);
--login-container-text: #666;
--login-form-color: transparent;
--login-form-text: #c8cfd5;
--login-btn-color: #f8951d;
--login-btn-color-rgb: 248,149,29;
--login-btn-text: #fff;
--login-btn-border: #f8951d;
--login-btn-border-radius: 23px;
--login-btn-hover-color: #d98918;
--login-btn-hover-color-rgb: 217,137,24;
--login-btn-hover-text: #fff;
--login-link-color: #fff;
--login-link-color-hover: #ddd;
--login-footer-color: rgba(255,255,255,.9);
--login-footer-text: #666;

/* Buttons */

--button-radius: .25rem;

/* Footer */

--footer-color: #f8951d;
--footer-text: #fff;

/* Error */

--danger-color: #c10003;
--danger-color-rgb: 193, 0 3;

}