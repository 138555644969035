.login {
  color: #000;
  display: flex;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  margin: 0;
}  

.login a {
  color:var(--login-link-color);
}

.login a:hover {
  color: var(--login-link-color)-hover;
}

.login {
  background-color: var(--login-color);
  background-image: var(--login-img);
  background-repeat: var(--login-img-repeat);
  background-position: var(--login-img-position);
  background-attachment: var(--login-img-attachment);
  background-size: var(--login-img-size);

}

.login main {
  flex: 1;
  background: var(--login-container-color);
  width: 100%;
  margin-left: 0;
  margin-right: auto;
}

.login .login .card-header h2 {
  /* Welcome message */
  margin-bottom: 2rem;
}

footer {
  background: var(--login-footer-color);
  margin-top: 0;
  width: 100%;
  display: flex;
  color: var(--login-footer-text);
  margin-left: 0;
  margin-right: auto;
  justify-content: center;
  align-items: start;
}

.login .card-header h2 {
  color: var(--login-welcome-color);
}

/* Buttons */

.btn-login.btn-lg {
	min-width: 180px;
	height: 50px;
}

.btn-login {
	background-color: var(--login-btn-color);
	border-color: var(--login-btn-border);
    border-radius: var(--login-btn-border-radius);
	color: var(--login-btn-text);
}

.btn-login:hover {
	background-color: var(--login-btn-hover-color) !important;
	border-color: var(--login-btn-hover-color) !important;
	color: var(--login-btn-hover-text) !important;
}

.btn-login:focus,
.btn-login.focus,
.btn-outline-login:focus,
.btn-outline-login.focus {
	box-shadow: none;
	background-color: var(--login-btn-hover-color);
	border-color: var(--login-btn-hover-color);
	color: var(--login-btn-hover-text);
}

.btn-login.disabled,
.btn-login:disabled {
	background-color: var(--login-btn-color);
	border-color: var(--login-btn-border);
	color: var(--login-btn-text);
}

.btn-login:not(:disabled):not(.disabled):active,
.btn-login:not(:disabled):not(.disabled).active,
.show > .btn-login.dropdown-toggle {
	background-color: var(--login-btn-hover-color);
	border-color: var(--login-btn-hover-color);
}

.btn-outline-login:not(:disabled):not(.disabled):active,
.btn-outline-login:not(:disabled):not(.disabled).active,
.show > .btn-outline-login.dropdown-toggle {
	background-color: var(--login-btn-hover-color);
	border-color: var(--login-btn-hover-color);
}

.btn-login:not(:disabled):not(.disabled):active:focus,
.btn-login:not(:disabled):not(.disabled).active:focus,
.show > .btn-login.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(var(--login-btn-hover-color), 0.5);
}

.btn-outline-login:not(:disabled):not(.disabled):active:focus,
.btn-outline-login:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-login.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(var(--login-btn-hover-color), 0.5);
}

.login .form-control {
	border: 0;
	border-radius: 0 !important;
  background: var(--login-form-color);
}

.login .form-control {
  border-bottom: 1px solid var(--login-form-text) !important;
}

.login .form-control:focus {
  box-shadow: none !important;
  border-color: var(--primary-color) !important;  
}

.login #username,
.login #newpass,
.login #lastPasswordInput,
.login #newPasswordInput,
.login #repeatNewPasswordInput {
  margin-bottom: 3rem;
}

.login .enter {
  margin-top: 5rem;
}

/* icon */

.input-password-container {
  position: relative;
}

.login .icon .mdi {
  position: absolute;
  color: var(--primary-color);
  top: 15px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  transition: all 1s;
}

.login .icon i:first-of-type {
  opacity: 1;
  transform: scale(1);
}

.login .icon i:last-of-type {
  opacity: 0;
  transform: scale(0);
}

.login .icon i.active:first-of-type {
  opacity: 0;
  transform: scale(0);
}

.login .icon i.active:last-of-type {
  opacity: 1;
  transform: scale(1);
}

/* floating form */

.login .form-floating {
  margin-bottom: 4rem;
}

.login .form-floating .form-control {
  font-size: 17px;
}

.login .form-floating > label {
  font-size: 17px;
  color: #6c757d !important;
  padding: 0 2px !important;
  top: 15px;
  left: 8px;
}

.login .form-floating > .form-control:focus ~ label,
.login .form-floating > .form-control:not(:placeholder-shown) ~ label,
.login .form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-2rem) translateX(0rem);
}

/* Autofix FIX for Firefox and Chrome. */

.login input {
  filter: none !important;
}

.login input:-webkit-autofill,
.login input:-webkit-autofill:hover,
.login input:-webkit-autofill:focus,
.login input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.login input:-webkit-autofill::first-line {
  font-size: 16px;
}

@media (min-width: 992px) {
  .login main,
	.login footer	{
		width: 550px;		        
	}	
}