.preload {
  position:absolute;
  top:-9999px;
  left:0;
}

.bar {
  height: 60px;
  position: fixed;
  margin-top: 0;
  border-radius: 0px;
  width: 100%;
  background: #fff;
  left: 0;
  display: flex;
  flex-direction: row;
  color: #8c91a8;  
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 6px;
  z-index: 100;
  overflow: visible;
}

.bar .nav {
}

.bar .nav-item {
  margin-bottom: 1rem;
  padding-left: 0rem;
  display: flex;
  align-items: center;
}

.bar .nav-link {
  transition: none;
}

.bar .nav-link {
  color: #393939;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.bar .nav-link img {
  margin-right: 15px;
}

.nav-item.active .icon,
.nav-item .icon {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
  margin-right: 13px;
  margin-left: 10px;
}

.bar {
}

.bar .nav-item a:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.bar .nav-item.dashboard a {
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.bar .nav-item.catalogue a .icon {    
  background-image: url('../../../assets/img/ico-catalogue.png')
}

.bar .nav-item.catalogue.active .icon,
.bar .nav-item.catalogue a:hover .icon {  
  background-image: url('../../../assets/img/ico-catalogue-on.png');
}

.bar .nav-item.courses-enrolled a .icon {  
  background-image: url('../../../assets/img/ico-courses-enrolled.png');
}

.bar .nav-item.courses-enrolled.active .icon,
.bar .nav-item.courses-enrolled a:hover .icon {  
  background-image: url('../../../assets/img/ico-courses-enrolled-on.png');
}

.bar .nav-item.completed-courses a .icon {  
  background-image: url('../../../assets/img/ico-completed-courses.png');
}

.bar .nav-item.completed-courses.active .icon,
.bar .nav-item.completed-courses a:hover .icon {  
  background-image: url('../../../assets/img/ico-completed-courses-on.png');
}

.bar .nav-item.logout a .icon {  
  background-image: url('../../../assets/img/ico-logout.png');
}

.bar .nav-item.logout.active .icon,
.bar .nav-item.logout a:hover .icon {  
  background-image: url('../../../assets/img/ico-logout-on.png');
}

.bar .nav-item.active a {
  color: var(--primary-color);
}

.bar .nav-item.active a:hover {
  text-decoration: none !important;
}

.bar .nav-item.active {
  background-image: url('../../../assets/img/nav-selected.png');
  background-repeat: no-repeat;
  background-position: -1px;  
}

.bar .start {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0;
}

.bar .start .logo img {
  height: 35px;
  margin-top: 10px;
}

.bar .start .avatar {  
  margin-top: 2rem;
  /*height: 250px;*/
}

.bar .start .avatar .picture {
  width: 150px;
  height: 150px;
  background-size: cover;
}

.bar .menu {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 60px);
}

.bar .menu .usagers {
  margin-bottom: 1rem;
}

.bar .end {
  display: flex;  
  margin-left: 1rem;
  margin-right: 1rem;
  flex-direction: column;
  justify-content: end;  
}

.bar .end .nav-item {
  padding-left: 0;
}

.bar .button {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 1rem;
}

.bar .button button:focus {
  color: transparent;
  outline: none;
}

/**/

.bar #collapsingNavbar {
  left: 0;
  top: 60px;
  background: #fff;
}

.bar #collapsingNavbar.collapse,
.bar #collapsingNavbar.collapsing {
  position: absolute;
  width: 100%;
}

.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--primary-color);
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 1px;
}

.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}

.navbar-toggler.collapsed .toggler-icon {
  background: var(--secondary-color);
}

@media (min-width: 992px) {
  .bar {
    flex-flow: column;
    height: 100%;
    max-width: 320px;
    overflow: auto;    
  }

  .bar .nav {
    display: flex;
  }

  .bar .nav-item {
    padding-left: 1rem;
  }

  .bar .start {
    margin-top: 65px;
  }

  .bar .start .logo img {
    height: 100%;
    margin-top: 0;
  }

  .bar .end {    
    height: 100%;
  }

  .bar .button {
    display: none;
  }

  .bar .nav-item.separe {
    height: 1px;
    border: 0;
    background: #eaeaef;
    width: 205px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: block;
  }  

  .nav-item.active .icon,
  .nav-item .icon {
    width: 30px;
    height: 30px;
    background-size: inherit;
  }

  .bar .menu {
    padding-top: 0;
    height: 100%;
  }
  
  .bar #collapsingNavbar {
    display: block;
    top: -5px;
  } 

  .bar #collapsingNavbar.collapse,
  .bar #collapsingNavbar.collapsing {
  position: relative;
  height:100%;
}
}