.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

.table-wrapper table {
  width: 100%;
  border-spacing: 0;
  /* white-space: nowrap; */
}

.table-wrapper th,td {
  border: none;
  margin: 0;
  padding: 10px 15px;
  width: 1%;
  box-sizing: border-box;
}

.table-wrapper table tbody tr {
  border-bottom: 1px solid #eee;
}
.table-wrapper table tbody tr:last-child {
  border-bottom: none;
}

.table-pagination {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0!important;
}

.table-pagination button {
  width: 24px!important;
  height: 24px!important;
  background: white;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 0px;
}
.table-pagination .page-numeration {
  padding: 0 0.5em;
  color: black;
}
.table-pagination button:enabled:hover {
  cursor: pointer;
}
.table-pagination button:enabled:hover,
.table-pagination button:enabled:active {
  background-color: #e6e6e6;
  outline: 0px;
}
.table-pagination button:enabled:focus {
  outline: 0px;
}
.table-pagination button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.table-pagination button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

#dashboard-list .table .justify-content-center{
  justify-content: left !important;
}

#dashboard-list-placeholder .table .justify-content-center{
  justify-content: left !important;
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}