html,
#root { /* React container */
  height: 100%;
  font-size: 13px;
  min-height: 100vh;
}

body {
  display: flex;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  background-color: #f4f6fa;
}

.consum {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  background: #f4f6fa;
  border-radius: 10px;
  margin-top: 90px;
}

.container-fluid {
  padding: 0;
  flex: 1;
}

.wrapper {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  margin-left: 30px;
  margin-right: 30px;
}

.main {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
}

.banner {
  background-image: url("../../assets/img/vector1.png"), linear-gradient(135deg,var(--primary-color), var(--primary-color-light));
  display: flex;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 0px 4px rgba(0, 71, 178, 0.17), 0px 0px 20px rgba(0, 71, 179, 0.07);
  padding: 20px 25px;
  min-height: auto;
}

.banner h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 0;
}

.banner p {
  color: #fff;
  font-size: 15px;
  margin-top: 0.45rem;
  margin-bottom: 0;
  padding-right: 0;
}

.tabs {
}

.tabs-body {
  margin-left: -5px;
  margin-right: -5px;
}
 
.tabs-header-container {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border-bottom: 5px solid #e4e4e4;
  overflow: visible !important;
}

.tabs li {
  font-size: 14px;
  font-weight: 700;
}

.tabs .nav-link {
  position: relative;
  padding: 0.5rem;
  color: #373737;
  display: inline-block;
}

.tabs .nav-link:hover,
.tabs .nav-link:focus {
  color: var(--primary-color);
}

.tabs .nav-link.active {
  color: var(--primary-color);
  background-color: var(--primary-color-lighter);
}

.tabs .buttons {
}

.tabs .has-search {
  position: relative;
}

.tabs .has-search .form-control {
  padding-right: 2.375rem;
}

.tabs .has-search .mdi {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  right: 0;
  top: 0;
  font-size: 18px;
}

.info div[class^="col-"] {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.info .card {
  --bs-card-border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  transition: all 0.3s ease-in-out;
}


.info .card:hover {
  box-shadow: none;
}

.info .card .container-picture {
  height: 200px;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}

.info .card .picture {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 100%;
  transition: 0.7s;
  z-index: 666;
}

.picture-loading {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 100%;
  transition: 0.7s;
  z-index: 666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info .card .picture:hover,
.info .card .above:hover+.picture {
  transform: scale(1.5)
}

.info .card .text-in-picture {
  position: absolute;
  bottom: 0;
  z-index: 99;
}

.info .card .score {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fff;
  z-index: 67;
}

.info .card .score .first-number {
  color: #3d3d3d;
}

.info .card .score .last-number {
  color: #898989;
}

.info .card .score .separator {
  height: 1px;
  width: 34px;
  border-bottom: 1px solid #3d3d3d;
  margin-top: -6px;
}

.info .card .score.green {
  border: 9px solid #4f9a27;
}

.info .card .score.red {
  border: 9px solid #ff5268;
}

.info .card .score.yellow {
  border: 9px solid #fbff08;
}

.info .card a:hover {
  color: var(--primary-color) !important;
}

.info .card .more-info {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.info #list {
  box-shadow: 0px 3px 6px #00000029;
}

.info #list table td,
.info #list table th {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #eee;
  font-size: 15px;
}

.info #list table tr:last-child td {
  border-bottom: 0;
}

.info #list table thead .mdi {
  color: #000;
}

.consum footer {
  width: 100%;
  margin-left: 0;
  padding: 0 30px;
  background: #f4f6fa;
}

.consum footer .list-group-item {
  position: initial;
}

@media (min-width: 576px) {}

@media (min-width: 768px) {  
  .tabs li {
    font-weight: 700;
    line-height: 17px;
  }

  .tabs .nav-link {
    padding-bottom: 0.5rem; 
    height: 49px;
    margin-top: -5px;
    bottom: -5px;     
  }

  .tabs .nav-link.active {   
    border-bottom: 5px solid var(--primary-color); 
    background-color: transparent;  
  }

  .tabs .buttons {
    position: relative;
    top: 9px;
    margin-top: -17px;    
  }  

}

@media (min-width: 992px) {   
  .container {
    margin-top: 35px;
  }

  .main {
    width: calc(100% - 320px) !important;
    margin-left: 320px;
  }

  .consum footer {
    width: calc(100% - 320px);
    margin-left: 320px;
    padding: 0 30px;
  }

  .banner {
    background: transparent;    
    background-image: url("../../assets/img/vector1.png"), linear-gradient(135deg,var(--primary-color), var(--primary-color-light));
    background-position: 85%;
    background-repeat: no-repeat;
    position: relative;
    min-height: 150px;
    padding: 0 40px;
  }  

  .banner p {
    padding-right: 250px;
  }

  .banner img {
    width: auto;
    position: absolute;
    right: -10px;
    top: -12px;
  }

  .banner h1 {
    font-size: 35px;
  }
  
  .banner p {
    font-size: 17px;
  }
}

/* Modal */

.modal-header .btn-close {
  position: absolute;
  right: 6px;
  font-size: 20px;
}

.modal-header .btn-close:focus {
  box-shadow: none;
}

.modal-body table th,
.modal-body table td {
    padding-top: 10px;
    padding-bottom: 10px;
  }


/* Categorie */  

.categorie {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  opacity: .8;
  transition: opacity 0.5s;
}

.categorie:hover {
  opacity: 1;
}

.categorie:hover div:last-child{
  text-decoration:underline;
}

.categorie .image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 150px;
  min-height: 150px;
  transition: 0.7s;
  border-radius: 100%;
}

@media (min-width: 1200px) {   
}

@media (min-width: 1400px) {
}